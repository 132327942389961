  import React from "react"
import {graphql} from 'gatsby'
import styled from 'styled-components'
import ArticleTemplate from "./article-template";

const Style = styled.div`

  #toc {
    display: none
  }
  .title {
  font-style: italic;
  margin-bottom: -20px;
  }

  .imageblock {
    margin-bottom: 2em;
  }

  .videoblock {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    margin-bottom: 24px;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const convertToHeadings = (toc) => {
  // Add TOC title as title
  const out = [];

  const extractHeadlines = (block, depth) => {
    for (let i = 0; i < block.length; i++) {
      // Add link
      const a = block[i].children[0]
      out.push({
        value: a.innerText,
        href: a.attributes.href.nodeValue.substr(1),
        depth: depth-1
      });

      // Add children
      if (block[i].children[1]) {
        extractHeadlines(block[i].children[1].children, depth + 1);
      }
    }
  }

  if (toc && toc.children.length >= 2) {
    extractHeadlines(toc.children[1].children, 2);
  }

  return out.length > 1 ? out : undefined;
}

const ArticleAsciidoc = ({ data, pageContext, location }) => {
  const {asciidoc} = data
  const {pageAttributes} = asciidoc
  const [toc, setToc] = React.useState(null);
  const container = React.useRef();
  const {
    breadcrumb: {crumbs},
  } = pageContext

  React.useEffect(() => {
    setToc(convertToHeadings(container.current.children.namedItem('toc')));
  }, [])
  const slug = location.pathname.substr(1 + process.env.SITE_PATH_PREFIX.length);

  const frontmatter = {
    title: asciidoc.document.title,
    abstract: pageAttributes.abstract,
    goals: pageAttributes.goals,
    prerequisites: pageAttributes.prerequisites,
    audience: pageAttributes.audience,
    worktime: pageAttributes.worktime,
    is_outdated: pageAttributes.is_outdated,
    version: pageAttributes.version,
    versions: pageAttributes.versions,
    showinnavigation: pageAttributes.showinnavigation,
    contentType: pageAttributes.contenttype
  }

  // Remove first <h1>…</h1> from asciidoc HTML
  let html = asciidoc.html
  if (html.startsWith('<h1>')) {
    const index = html.indexOf('</h1>')
    html = html.substr(index + 5)
  }

  return (
    <ArticleTemplate
      title={asciidoc.document.title}
      frontmatter={frontmatter}
      toc={toc}
      modifiedGmt={asciidoc.pageAttributes.modified || asciidoc.parent.mtime}
      performTime={undefined}
      createdGmt={undefined}
      crumbs={crumbs}
      slug={slug}
    >
        <Style className={'article'} ref={container} dangerouslySetInnerHTML={{ __html: html }} />
    </ArticleTemplate>
  )
}

export const pageQuery = graphql`
    query($id: String!) {
        asciidoc(id: {eq: $id}) {
            author {
                lastName
                authorInitials
            }
            document {
                main
                subtitle
                title
            }
            html
            pageAttributes {
                abstract
                audience
                goals
                prerequisites
                worktime
                slug
                modified
                is_outdated
                version
                versions
                showinnavigation
                contenttype
            }
            parent {
                ... on File {
                    mtime
                }
            }
        }
    }
`

export default ArticleAsciidoc;
